import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { GetColor, Icon, Notifications, NotificationType } from 'venn-ui-kit';
import { assertExhaustive, LibraryItemType, LibraryTab, navigateToLibrary } from 'venn-utils';
import type { UniversalUploaderContextValue } from '../../contexts/universal-uploader';
import SidePanelOverlay from '../../side-panel-overlay/SidePanelOverlay';
import { InvestmentDataUploader } from './InvestmentDataUploader';
import { DataUploaderMode, type PortfolioMultiUploaderProps, type PortfolioMultiUploaderView } from './types';
import { ChooseActionView } from './views/ChooseActionView';
import { ChoosePortfolioActionView } from './views/ChoosePortfolioActionView';
import MultiPortfolioUploader from './views/MultiPortfolioUploader';
import PortfolioCreator from './views/PortfolioCreator';
import UniversalUploaderContext from '../../contexts/universal-uploader';
import CreatePrivatePortfolioUniversalUploaderStep from './CreatePrivatePortfolioUniversalUploaderStep';
import { IChooseActionView } from './constants';

/** Universal Uploader supporting bulk-portfolio upload  */
export const UniversalUploader = ({
  lastTimeBeforeUpdate,
  onClose,
  onPrivatePortfolioCreated,
}: PortfolioMultiUploaderProps) => {
  const mainContentElement = document.getElementById('main-content');
  const history = useHistory();

  const onCompleteUploader = useCallback(
    (mode: DataUploaderMode, uploadedFundIds?: string[]) => {
      Notifications.notify('Upload Successfully Completed!', NotificationType.SUCCESS);
      onClose();
      navigateToLibrary(history, {
        tab: mode === DataUploaderMode.Privates ? LibraryTab.PrivateAssets : LibraryTab.ReturnsData,
        selectedIds: uploadedFundIds ?? [],
        selectedFilters: {
          itemType: LibraryItemType.UPLOAD,
          quickFilters: [],
          tags: [],
        },
        lastTimeBeforeUpdate,
      });
    },
    [history, onClose, lastTimeBeforeUpdate],
  );

  const [view, setView] = React.useState<PortfolioMultiUploaderView>(IChooseActionView);

  const contextValue: UniversalUploaderContextValue = useMemo(() => {
    return {
      closeFlyout: onClose,
      setView,
    };
  }, [onClose]);

  const renderView = () => {
    switch (view.id) {
      case 'CHOOSE_ACTION':
        return <ChooseActionView setView={setView} />;
      case 'CHOOSE_ACTION_PORTFOLIO':
        return <ChoosePortfolioActionView setView={setView} />;
      case 'UPLOAD_RETURNS':
      case 'UPLOAD_PRIVATES':
      case 'REVIEW_PRIVATES':
      case 'REVIEW_RETURNS': // fallback to old uploader implementation
        return <InvestmentDataUploader viewId={view.id} setView={setView} onCompleteNavigate={onCompleteUploader} />;
      case 'UPLOAD_PORTFOLIO':
      case 'REVIEW_PORTFOLIO':
        return <MultiPortfolioUploader />;
      case 'NEW_PORTFOLIO':
      case 'DUPLICATE_PORTFOLIO':
        return <PortfolioCreator mode={view.id} />;
      case 'CREATE_PRIVATE_PORTFOLIO':
        return (
          <CreatePrivatePortfolioUniversalUploaderStep
            onCancel={() => setView(IChooseActionView)}
            onPrivatePortfolioCreated={(portfolio) => {
              Notifications.notify('Portfolio Successfully Created!', NotificationType.SUCCESS);
              onClose();
              onPrivatePortfolioCreated(portfolio);
            }}
          />
        );
      default:
        throw assertExhaustive(view.id);
    }
  };

  return mainContentElement ? (
    <SidePanelOverlay
      margin={0}
      hideTopBackButton
      className="portfolio-multi-uploader"
      isOpen
      side="right"
      handleClose={onClose}
      width={1060}
      noPadding
      scrollAlignRight
      mainContentElement={mainContentElement}
      closeOnEsc
      content={
        <UniversalUploaderContext.Provider value={contextValue}>
          <MainContainer>
            <HeaderContainer>
              <HeaderText>
                <HeaderTitle>{view.title}</HeaderTitle>
                {view.subtitle && <HeaderSubtitle>{view.subtitle}</HeaderSubtitle>}
              </HeaderText>
              <button type="button" onClick={onClose} className="qa-close-panel">
                <Icon type="close" />
              </button>
            </HeaderContainer>
            {renderView()}
          </MainContainer>
        </UniversalUploaderContext.Provider>
      }
    />
  ) : null;
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 300;
`;
const HeaderSubtitle = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px 24px;
  font-size: 32px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid ${GetColor.GreyScale.Grey30};
  line-height: normal;
`;
