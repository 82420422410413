import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { ModalFooter } from '../../../../modal/ModalFooter';
import { GetColor } from 'venn-ui-kit';

interface FooterButtonsProps {
  disabled?: boolean;
  disabledMessage?: React.ReactNode;
  onContinue?: () => void;
  onCancel?: () => void;
  onStartOver?: () => void;
  hasNoData?: boolean;
  isSample?: boolean;
  primaryLabel?: string | JSX.Element;
  primaryTooltip?: string;
  secondaryLabel?: string | JSX.Element;
  className?: string;
  noMargin?: boolean;
}

export const UniversalUploaderFooter: FC<React.PropsWithChildren<FooterButtonsProps>> = ({
  disabled,
  disabledMessage,
  onContinue,
  onCancel,
  onStartOver,
  hasNoData,
  isSample,
  primaryLabel,
  primaryTooltip,
  secondaryLabel,
  noMargin,
}) => {
  return (
    <StyledModalFooter
      noMargin={noMargin}
      simplifiedButtonStyles
      className="no-margin-footer"
      cancelLabel="< BACK"
      cancelClassName="qa-upload-cancel"
      onCancel={isSample ? undefined : onCancel}
      rightChildren={disabledMessage}
      primaryLabel={hasNoData ? '' : primaryLabel ?? 'Complete Upload'}
      primaryClassName="qa-accept-btn"
      primaryDisabled={disabled}
      primaryTooltip={primaryTooltip}
      onPrimaryClick={onContinue}
      secondaryLabel={secondaryLabel ?? isSample ? 'Try with Your Data' : 'Start Over'}
      onSecondaryClick={onStartOver}
    />
  );
};

export const StyledModalFooter = styled(ModalFooter)<{ noMargin?: boolean }>`
  width: 100%;
  background-color: ${GetColor.White};
  padding: 12px 24px;
  min-height: 64px;
  ${({ noMargin }) => noMargin && 'margin-top: 0;'}
  .qa-upload-cancel {
    border: 1px solid ${GetColor.Primary.Dark};
    border-radius: 4px;
  }
`;
