import type { ReactNode } from 'react';
import React from 'react';
import type { Hotkey, FontAwesomePrefixes } from 'venn-utils';
import { analyticsService, FS, Routes } from 'venn-utils';
import { getTextThemeProvider, HOVER_PARENT_CLASSNAME, StudioIcon } from 'venn-ui-kit';
import { compact } from 'lodash';
import type { History } from 'history';
import type { ActionEnum } from 'venn-api';

interface NavigationConfig {
  search?: NavigationItemConfig;
  left: NavigationItemConfig[][];
  bottom: NavigationItemConfig[];
  quickLinks: NavigationItemConfig[];
}

export interface NavigationSubItem {
  label: string;
  onClick?: (e?: React.MouseEvent) => void;
  path?: string;
  notification?: boolean;
  className?: string;
}

export interface NavigationItemConfig {
  navId: string;
  icon?: string;
  /** [Font Awesome](https://fontawesome.com/) prefixes */
  iconPrefix?: FontAwesomePrefixes;
  iconComponent?: JSX.Element;
  label: string | ReactNode;
  path?: string;
  /**
   * For regular links, the nav item is highlighted if the location matches `path`. Defining `path` also determines that
   * the nav item SHOULD be rendered as a regular link.
   *
   * For pseudo links with custom `onClick` that aren't meant to be rendered as regular links, `pseudoPath` can be used
   * to determine if the location matches the defined `pseudoPath` and to provide the highlighted style.
   */
  pseudoPath?: string;
  excludedPath?: string;
  external?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
  hotkey?: Hotkey;
  className?: string;
  wrapperStyle?: string;
  requiredPermission?: ActionEnum;
}

export interface BaseTrackingOpts {
  location: string;
  itemType: string;
  userIntent: string;
}

export interface NavActions {
  openUploaderModal: () => void;
  openMultiPortfolioUploader: () => void;
  openCreatePortfolioModal: () => void;
  openPortfolioLab: (trackingOpts: BaseTrackingOpts) => void;
  toggleStudioSlideout?: () => void;
  toggleReportLabSlideout?: () => void;
  toggleReportLabLearnMore?: () => void;
  togglePrivateAssetLabLearnMore?: () => void;
}

const baseTrackingOpts: BaseTrackingOpts = {
  location: 'navigation bar',
  itemType: 'link',
  userIntent: 'navigate',
};

type NavigationLinks =
  | 'analyze'
  | 'portfolioLab'
  | 'compare'
  | 'studio'
  | 'reportLab'
  | 'reportLabNoAccess'
  | 'library'
  | 'reporting'
  | 'privateAssetLabUpsell';

interface RoutingStatus {
  isStudioBlock?: boolean;
}

const getNavigationLinks = (
  navActions: NavActions,
  history: History<unknown>,
  { isStudioBlock = false }: RoutingStatus,
): Record<NavigationLinks, NavigationItemConfig> => ({
  analyze: {
    navId: 'analyze',
    icon: 'calculator',
    label: 'Analysis',
    className: 'qa-navigation-analyze',
    path: Routes.ANALYSIS_PATH,
    excludedPath: Routes.ANALYSIS_COMPARE_PATH,
    onClick: (e) => {
      if (e) {
        analyticsService.navigationTriggered({
          ...baseTrackingOpts,
          destinationPageTitle: 'Analysis',
        });
      }
    },
  },
  portfolioLab: {
    navId: 'portfolioLab',
    icon: 'flask',
    label: 'Portfolio Lab',
    className: 'qa-navigation-portfolio-lab',
    pseudoPath: Routes.PORTFOLIO_LAB_PATH,
    onClick: () => navActions.openPortfolioLab(baseTrackingOpts),
    requiredPermission: 'OPTIMIZATION',
  },
  compare: {
    navId: 'compare',
    icon: 'line-columns',
    iconPrefix: 'fad',
    label: 'Compare',
    className: 'qa-navigation-compare ',
    path: Routes.ANALYSIS_COMPARE_PATH,
    onClick: (e) => {
      if (e) {
        if (history.location?.pathname?.startsWith(Routes.ANALYSIS_COMPARE_PATH)) {
          // If we are already on the compare page don't trigger a nav change.
          e.preventDefault();
        }
        analyticsService.navigationTriggered({
          ...baseTrackingOpts,
          destinationPageTitle: 'Comparison',
        });
      }
    },
    requiredPermission: 'COMPARE',
  },
  studio: {
    navId: 'studio',
    iconComponent: <StudioIcon navSelected={isStudioBlock} isNav hoverHighlight />,
    label: 'Studio',
    className: `qa-navigation-studio ${HOVER_PARENT_CLASSNAME}`,
    pseudoPath: Routes.STUDIO_PATH,
    onClick: (e) => {
      if (e) {
        navActions.toggleStudioSlideout?.();

        analyticsService.navigationTriggered({
          ...baseTrackingOpts,
          destinationPageTitle: 'Studio',
        });
      }
    },
  },
  reportLab: {
    navId: 'reportLab',
    icon: 'file-chart-column',
    label: 'Report Lab',
    className: `qa-navigation-report-lab ${HOVER_PARENT_CLASSNAME}`,
    pseudoPath: Routes.REPORT_LAB_PATH,
    onClick: (e) => {
      if (e) {
        navActions.toggleReportLabSlideout?.();

        analyticsService.navigationTriggered({
          ...baseTrackingOpts,
          destinationPageTitle: 'Report Lab',
        });
      }
    },
  },
  reportLabNoAccess: {
    navId: 'reportLabNoAccess',
    icon: 'file-chart-column',
    label: 'Report Lab',
    className: 'qa-navigation-no-access',
    onClick: (e) => {
      if (e) {
        navActions.toggleReportLabLearnMore?.();

        analyticsService.navigationTriggered({
          ...baseTrackingOpts,
          destinationPageTitle: 'Learn More About Report Lab',
        });
      }
    },
  },
  privateAssetLabUpsell: {
    navId: 'privateAssetLabUpsell',
    icon: 'square-p',
    label: 'Private Asset Lab',
    className: FS.has('private_analytics')
      ? 'qa-navigation-pal-upsell'
      : 'qa-navigation-pal-upsell qa-navigation-no-access',
    onClick: (e) => {
      if (e) {
        navActions.togglePrivateAssetLabLearnMore?.();
        analyticsService.navigationTriggered({
          ...baseTrackingOpts,
          destinationPageTitle: 'Learn More About Private Asset Lab',
        });
      }
    },
  },
  library: {
    navId: 'library',
    icon: 'database',
    label: <>Data Library</>,
    className: 'qa-navigation-library',
    path: Routes.LIBRARIES_PATH,
    onClick: (e) => {
      if (e) {
        analyticsService.navigationTriggered({
          ...baseTrackingOpts,
          destinationPageTitle: 'Library',
        });
      }
    },
  },
  reporting: {
    navId: 'reporting',
    icon: 'book',
    label: 'Reporting',
    className: 'qa-navigation-reporting',
    path: Routes.REPORTS,
    onClick: (e) => {
      if (e) {
        analyticsService.navigationTriggered({
          ...baseTrackingOpts,
          destinationPageTitle: 'Reporting',
        });
      }
    },
  },
});

const getQuickLinks = (navActions: NavActions): NavigationItemConfig[] => {
  return compact([
    {
      navId: 'upload',
      icon: 'upload',
      label: 'Upload',
      className: 'qa-navigation-upload ',
      onClick: navActions.openUploaderModal,
      requiredPermission: 'UPLOAD_RETURNS',
    },
    {
      navId: 'new-portfolio',
      icon: 'th',
      label: 'New portfolio',
      className: 'qa-navigation-create ',
      onClick: navActions.openCreatePortfolioModal,
      requiredPermission: 'CREATE_PORTFOLIO',
    },
  ]);
};

export const getMultiUploadButtonLink = (navActions: NavActions): NavigationItemConfig => {
  return {
    navId: 'universal-upload',
    icon: 'circle-plus',
    label: 'Portfolios & Investments',
    className: 'qa-navigation-universal-upload ',
    onClick: navActions.openMultiPortfolioUploader,
  };
};

const getBottomLinks = (): NavigationItemConfig[] => [
  {
    navId: 'factor-lens-education',
    icon: 'graduation-cap',
    label: 'Factor Lens Education',
    path: Routes.FACTOR_LENS_HOMEPAGE,
    onClick: () => {
      analyticsService.navigationTriggered({
        ...baseTrackingOpts,
        destinationPageTitle: 'Factor Lens Homepage',
      });
    },
  },
  {
    navId: 'help',
    icon: 'question-circle',
    label: 'Help',
    path: `https://${getTextThemeProvider().HelpCenterUrl}/`,
    onClick: () => {
      analyticsService.navigationTriggered({
        ...baseTrackingOpts,
        destinationPageTitle: 'Help',
      });
    },
  },
];

export const navigationConfigInitializer = (navActions: NavActions, history: History<unknown>): NavigationConfig => {
  const hasPortfolioLab = FS.has('optimization');
  const navigationLinks = getNavigationLinks(navActions, history, {});
  const hasReportLab = FS.has('studio_report_editor');
  const hasUpsellImprovements = FS.has('upsell_improvements_ff');

  return {
    left: [
      compact([
        hasUpsellImprovements && !hasReportLab && navigationLinks.reportLabNoAccess,
        hasUpsellImprovements && navigationLinks.privateAssetLabUpsell,
        navigationLinks.analyze,
        hasPortfolioLab && navigationLinks.portfolioLab,
        navigationLinks.compare,
        navigationLinks.library,
        navigationLinks.reporting,
      ]),
    ],
    quickLinks: getQuickLinks(navActions),
    bottom: getBottomLinks(),
  };
};

export const navigationConfigInitializerV1 = (navActions: NavActions, history: History<unknown>): NavigationConfig => {
  const hasPortfolioLab = FS.has('optimization');
  const hasStudio = FS.has('studio_ff');
  const hasReportLab = FS.has('studio_report_editor');
  const hasUpsellImprovements = FS.has('upsell_improvements_ff');
  const isStudioBlock = history.location?.pathname?.startsWith(Routes.STUDIO_PATH);
  const navigationLinks = getNavigationLinks(navActions, history, { isStudioBlock });

  const left = [
    compact([
      hasStudio && navigationLinks.studio,
      hasReportLab && navigationLinks.reportLab,
      hasUpsellImprovements && !hasReportLab && navigationLinks.reportLabNoAccess,
      hasUpsellImprovements && navigationLinks.privateAssetLabUpsell,
      {
        ...navigationLinks.analyze,
        requiredPermission: 'ANALYSIS_PAGE' as ActionEnum,
      },
      navigationLinks.compare,
      hasPortfolioLab && navigationLinks.portfolioLab,
    ]),
    [
      {
        ...navigationLinks.reporting,
        label: 'Saved Views',
      },
    ],
    [navigationLinks.library],
  ];

  const quickLinks = getQuickLinks(navActions);

  const bottom = getBottomLinks();

  return {
    left,
    quickLinks,
    bottom,
  };
};
