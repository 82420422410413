import type { PortfolioMultiUploaderView } from '../../types';
import { ColorUtils, Flexbox, GetColor, Icon } from 'venn-ui-kit';
import styled from 'styled-components';
import React, { type Dispatch, type SetStateAction } from 'react';

type OptionTileProps = {
  option: PortfolioMultiUploaderView;
  onClick: () => void;
  setViewTileHovered?: Dispatch<SetStateAction<PortfolioMultiUploaderView | undefined>>;
};
export const OptionTile = ({ option, onClick, setViewTileHovered }: OptionTileProps) => {
  return (
    <OptionTileButton
      aria-label={`option-tile-${option.id}`}
      key={option.id}
      onClick={onClick}
      onMouseEnter={() => setViewTileHovered && setViewTileHovered((_) => option)}
      onMouseLeave={() => setViewTileHovered && setViewTileHovered((_) => undefined)}
    >
      <Flexbox direction="column" justifyContent="flex-start" alignItems="flex-start" gap={4}>
        <OptionHeader>{option.title}</OptionHeader>
        <OptionDescription>{option.description}</OptionDescription>
        {option.highlightText && (
          <span>
            <Badge>NEW</Badge>
            &nbsp;
            <HighlightedFeatureDescription>{option.highlightText}</HighlightedFeatureDescription>
          </span>
        )}
      </Flexbox>
      <Icon type="angle-right" />
    </OptionTileButton>
  );
};

const OptionTileButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  align-items: center;
  width: 100%;
  border: 1px solid ${GetColor.Primary.Dark};
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 10px;
  text-align: left;

  &:hover {
    border-color: ${GetColor.Primary.Dark};
    background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
  }
`;

const HighlightedFeatureDescription = styled.span`
  color: ${GetColor.HighlightDark};
  font-size: 14px;
`;

const Badge = styled.span`
  display: inline-block;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${GetColor.HighlightDark};
  color: ${GetColor.White};
  line-height: 1.5;
  font-weight: 700;
  font-size: 10px;
  height: 14px;
`;

const OptionHeader = styled.div`
  color: ${GetColor.Primary.Dark};
  font-size: 20px;
`;

const OptionDescription = styled.div`
  color: ${GetColor.GreyScale.Grey70};
  font-size: 14px;
`;
