import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { GetColor } from 'venn-ui-kit';
import React from 'react';
import { type IndexedPortfolioParseResult, ParsedPortfolioOption } from './ParsedPortfolioOption';

type ScrollableSectionWithStickyHeaderProps = {
  parsedResults: IndexedPortfolioParseResult[];
  title: string;
  groupIndex: number;
  groupReverseIndex: number;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * Left section of the Multiportfolio Review Step.
 *
 * The headers of the sections are sticky and should be always visible,
 * the contents aren't sticky, but can be comfortably scrolled.
 * Multiple scrollable sections are supported, as long as they share the parent and the parent element doesn't scroll off-screen.
 */
export const ScrollableSectionWithStickyHeader = ({
  parsedResults,
  selectedIndex,
  setSelectedIndex,
  title,
  groupIndex,
  groupReverseIndex,
}: ScrollableSectionWithStickyHeaderProps) => {
  if (isEmpty(parsedResults)) {
    return null;
  }
  return (
    <>
      <StickyGroupHeader groupIndex={groupIndex} groupReverseIndex={groupReverseIndex}>
        <span>
          <b>{title}</b>
        </span>
        {parsedResults.length}
      </StickyGroupHeader>
      {parsedResults.map((data) => (
        <ParsedPortfolioOption
          setSelectedIndex={setSelectedIndex}
          selected={data.originalIndex === selectedIndex}
          data={data}
          key={data.originalIndex}
        />
      ))}
    </>
  );
};

const STICKY_CONTENT_HEIGHT = 43;
const StickyGroupHeader = styled.div<{ groupIndex: number; groupReverseIndex: number }>`
  display: flex;
  position: sticky;
  top: ${({ groupIndex }) => (STICKY_CONTENT_HEIGHT - 2) * groupIndex}px;
  bottom: ${({ groupReverseIndex }) => (STICKY_CONTENT_HEIGHT - 2) * groupReverseIndex}px;
  height: ${STICKY_CONTENT_HEIGHT}px;
  padding: 16px 16px 8px 24px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${GetColor.GreyScale.Grey30};
  background: ${GetColor.GreyScale.Grey10};
  color: ${GetColor.Black};
  width: 100%;
`;
