import React from 'react';
import styled from 'styled-components';
import { useHasFF } from 'venn-utils';
import { compact } from 'lodash';
import { type PortfolioMultiUploaderView } from '../types';
import {
  IChooseActionPortfolioView,
  ICreatePrivatePortfolioView,
  IUploadPrivatesView,
  IUploadReturnsView,
} from '../constants';
import { MainViewContainer, RightContainer, SectionContainer, StyledImage } from './shared/layout';
import { getSupportingImageSrc } from '../utils';
import { OptionTile } from './shared/OptionTile';

type ChooseActionSection = {
  title: string;
  options: PortfolioMultiUploaderView[];
};

const useSections = () => {
  const hasPrivates = useHasFF('private_analytics');
  const sections: ChooseActionSection[] = [
    {
      title: 'Investments',
      options: compact([IUploadReturnsView, hasPrivates ? IUploadPrivatesView : null]),
    },
    {
      title: 'Portfolios',
      options: compact([IChooseActionPortfolioView, hasPrivates ? ICreatePrivatePortfolioView : null]),
    },
  ];

  return sections;
};

type ChooseActionViewProps = {
  setView: (view: PortfolioMultiUploaderView) => void;
};

export const ChooseActionView = ({ setView }: ChooseActionViewProps) => {
  const sections = useSections();
  const [viewTileHovered, setViewTileHovered] = React.useState<PortfolioMultiUploaderView | undefined>(undefined);
  const imgSrc = getSupportingImageSrc(viewTileHovered);

  return (
    <MainViewContainer>
      <LeftContainer>
        {sections.map((section) => (
          <SectionContainer key={section.title}>
            {section.title}
            {section.options.map((option) => (
              <OptionTile
                setViewTileHovered={setViewTileHovered}
                onClick={() => setView(option)}
                option={option}
                key={option.id}
              />
            ))}
          </SectionContainer>
        ))}
      </LeftContainer>
      <RightContainer>{!!imgSrc && <StyledImage src={getSupportingImageSrc(viewTileHovered)} />}</RightContainer>
    </MainViewContainer>
  );
};

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 0 0 24px;
  max-width: 488px;
`;
