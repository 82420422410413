import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import type { DropMenuItem } from 'venn-ui-kit';
import { DropMenu, GetColor, Headline3 } from 'venn-ui-kit';
import {
  type Sample,
  SamplePortfolioData,
  FileLayout,
  FileTypes,
  SampleReturnsData,
  SampleFrequency,
  SampleNavData,
  SamplePrivatesData,
} from './SampleData';
import SampleCard from './SampleCard';
import { DataUploaderMode } from '../types';
import type { AnyDuringEslintMigration } from 'venn-utils';
import { plural } from 'venn-utils';

const getFiltersForEnum = function <T extends string | number>(
  menuEnum: T,
  allItemsLabel: string,
): DropMenuItem<T[]>[] {
  const values = Object.values(menuEnum);
  return [
    {
      value: values,
      label: allItemsLabel,
    },
    ...values.map((v) => ({
      value: [v],
      label: v,
    })),
  ];
};

const fileTypeItems = getFiltersForEnum<FileTypes>(FileTypes as AnyDuringEslintMigration, 'All File Types');

const layoutTypeItems = getFiltersForEnum<FileLayout>(FileLayout as AnyDuringEslintMigration, 'All Layouts');

const frequencyTypeItems = getFiltersForEnum<SampleFrequency>(
  SampleFrequency as AnyDuringEslintMigration,
  'All Frequencies',
);

const getSampleList = (mode: DataUploaderMode) => {
  switch (mode) {
    case DataUploaderMode.NewNavs:
      return SampleNavData;
    case DataUploaderMode.Privates:
      return SamplePrivatesData;
    case DataUploaderMode.Portfolios:
      return SamplePortfolioData;
    default:
      return SampleReturnsData;
  }
};

const DataGallery = ({ mode, isUniversalUploader }: { mode: DataUploaderMode; isUniversalUploader?: boolean }) => {
  const samples = getSampleList(mode);
  const [visibleSamples, setVisibleSamples] = useState<Sample[]>(samples);
  const [fileTypeFilter, setFileTypeFiler] = useState<DropMenuItem<FileTypes[]>>(fileTypeItems[0]);
  const [layoutTypeFilter, setLayoutTypeFilter] = useState<DropMenuItem<FileLayout[]>>(layoutTypeItems[0]);
  const [frequencyTypeFilter, setFrequencyTypeFilter] = useState<DropMenuItem<SampleFrequency[]>>(
    frequencyTypeItems[0],
  );

  useEffect(() => {
    setVisibleSamples(
      samples
        .filter((s) => fileTypeFilter.value.includes(s.type))
        .filter((s) => layoutTypeFilter.value.includes(s.layout))
        .filter((s) => !s.frequency || frequencyTypeFilter.value.some((v) => s.frequency?.includes(v))),
    );
  }, [fileTypeFilter, layoutTypeFilter, frequencyTypeFilter, samples]);

  return (
    <Container data-testid="data-gallery">
      {mode === DataUploaderMode.Returns && (
        <FilterBar>
          <FilterWrapper>
            <DropMenu items={fileTypeItems} onChange={setFileTypeFiler} selected={fileTypeFilter.value} />
          </FilterWrapper>
          <>
            <FilterWrapper>
              <DropMenu items={layoutTypeItems} onChange={setLayoutTypeFilter} selected={layoutTypeFilter.value} />
            </FilterWrapper>
            <FilterWrapper>
              <DropMenu
                items={frequencyTypeItems}
                onChange={setFrequencyTypeFilter}
                selected={frequencyTypeFilter.value}
              />
            </FilterWrapper>
          </>
          <AllTemplates>
            {plural(visibleSamples.length, {
              1: '{{count}} Available Template',
              other: '{{count}} Available Templates',
            })}
          </AllTemplates>
        </FilterBar>
      )}
      <DataContainer
        isReturnsView={mode === DataUploaderMode.Returns}
        isUniversalUploader={isUniversalUploader ?? false}
      >
        {mode === DataUploaderMode.Privates && <Headline3>Download one of the two Excel templates below:</Headline3>}
        {visibleSamples.map((sample) => (
          <SampleCard sample={sample} key={sample.tag} mode={mode} />
        ))}
      </DataContainer>
    </Container>
  );
};

export default DataGallery;

const FILTER_BAR_HEIGHT = 60;
const AllTemplates = styled.div`
  font-size: 10px;
  color: ${GetColor.HintGrey};
  text-align: right;
  flex: 1;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid ${GetColor.Grey};
`;

const DataContainer = styled.div<{ isUniversalUploader: boolean; isReturnsView: boolean }>`
  position: absolute;
  top: ${({ isReturnsView }) => (isReturnsView ? FILTER_BAR_HEIGHT : 0)}px;
  left: 0;
  bottom: 0;
  padding: 20px 30px 0 30px;
  overflow-y: auto;
`;

const FilterBar = styled.div`
  display: flex;
  align-items: center;
  height: ${FILTER_BAR_HEIGHT}px;
  width: 100%;
  background-color: ${GetColor.WhiteGrey};
  padding: 0 20px;
`;

const FilterWrapper = styled.div`
  padding-right: 10px;
`;
