/*
 * Venn Platform API 1.0.0 Operations for "multi"
 * Automatically generated file - do not modify!
 */

import type { MultiPortfolioParseResult, MultiPortfolioPersistInput, MultiPortfolioPersistResult } from '../types';
import type { OperationResult } from '../../utils';
import { buildRequestInit, extractError } from '../../utils';

/** Parse multi-portfolio excel file */
export async function parseFile(body?: any, signal?: AbortSignal): Promise<OperationResult<MultiPortfolioParseResult>> {
  const endpoint = '/api/multi-portfolio/parse';
  const requestInit = buildRequestInit({ method: 'POST', body, signal });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MultiPortfolioParseResult = (await response.json()) as MultiPortfolioParseResult;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
/** Persist multi-portfolio upload */
export async function persistMultiPortfolioUpload(
  body?: Partial<MultiPortfolioPersistInput>,
  signal?: AbortSignal,
): Promise<OperationResult<MultiPortfolioPersistResult>> {
  const endpoint = '/api/multi-portfolio/persist';
  const requestInit = buildRequestInit({
    method: 'POST',
    body: JSON.stringify(body),
    headers: { 'Content-Type': 'application/json' },
    signal,
  });
  const response = await fetch(endpoint, requestInit);
  if (response.status >= 200 && response.status < 400) {
    const content: MultiPortfolioPersistResult = (await response.json()) as MultiPortfolioPersistResult;
    return {
      content,
      status: response.status,
      headers: {},
    };
  }
  throw await extractError(response);
}
