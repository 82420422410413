import React from 'react';
import type { PortfolioMultiUploaderView } from './types';
import FaqLink from '../../faq-link/FaqLink';
import { PRIVATE_ASSET_LAB_FAQ_HREF } from 'venn-ui-kit';

export const IChooseActionView = {
  id: 'CHOOSE_ACTION',
  title: 'Create and update portfolios and investments',
  description: undefined,
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IUploadReturnsView = {
  id: 'UPLOAD_RETURNS',
  title: 'Upload investment return data',
  description: 'Add new or update existing returns-based investment(s)',
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IReviewReturnsView = {
  id: 'REVIEW_RETURNS',
  title: 'Review and validate returns data',
  description: undefined,
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IUploadPrivatesView = {
  id: 'UPLOAD_PRIVATES',
  title: 'Upload private asset data',
  description:
    'Add new or update existing private asset investment(s), including cash flows and other private asset metadata',
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IReviewPrivatesView = {
  id: 'REVIEW_PRIVATES',
  title: 'Review and validate private asset data',
  description: undefined,
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IChooseActionPortfolioView = {
  id: 'CHOOSE_ACTION_PORTFOLIO',
  title: 'Create or update pro forma portfolio(s)',
  description: 'Pro forma portfolio using static allocations, used for returns-based analytics',
  highlightText: 'Now upload multiple portfolios from a single file!',
} as const satisfies PortfolioMultiUploaderView;

export const IUploadPortfolioView = {
  id: 'UPLOAD_PORTFOLIO',
  title: 'Upload portfolio data',
  description:
    'Update existing or create new portfolio(s) by uploading NAVs and strategy hierarchies from a spreadsheet.',
  highlightText: 'Upload multiple portfolios’ NAVs & strategy hierarchies in a single file!',
} as const satisfies PortfolioMultiUploaderView;

export const IReviewPortfolioView = {
  id: 'REVIEW_PORTFOLIO',
  title: 'Preview and adjust portfolio data',
  description: undefined,
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IDuplicatePortfolioView = {
  id: 'DUPLICATE_PORTFOLIO',
  title: 'Duplicate an existing portfolio',
  description: 'Copy existing portfolio as a starting point to edit further',
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const INewPortfolioView = {
  id: 'NEW_PORTFOLIO',
  title: 'Build a new portfolio in Analysis',
  description: "Build your portfolio by adding investments from Venn's Data Library and assigning allocations",
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const ICreatePrivatePortfolioView = {
  id: 'CREATE_PRIVATE_PORTFOLIO',
  title: 'Create new private asset portfolio',
  subtitle: (
    <>
      Give your portfolio a name. Then, either select an existing portfolio template or create a new portfolio from
      scratch. Visit the <FaqLink link={PRIVATE_ASSET_LAB_FAQ_HREF} /> for more information.
    </>
  ),
  description: 'Contains only private asset data and is used for private asset-specific analytics',
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;
