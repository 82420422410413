import React from 'react';
import styled from 'styled-components';
import { BannerMessage } from '../../../../banner-message/BannerMessage';

interface ErrorMessageProps {
  children: string | React.ReactNode;
  className?: string;
}

const ErrorMessage = ({ children, className }: ErrorMessageProps) => (
  <StyledError className={className}>
    <BannerMessage errorType="error" small>
      {children}
    </BannerMessage>
  </StyledError>
);

export default ErrorMessage;

const StyledError = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;
