import { compact } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TABS, UPLOAD_TYPE_KEY } from 'venn-api';
import { analyticsService, useHasFF } from 'venn-utils';
import { UserContext } from '../../../contexts';
import { ModalFooter } from '../../../modal';
import SideMenu from '../../../side-menu/SideMenu';
import DataGallery from '../components/DataGallery';
import DataInput from '../components/DataInput';
import FaqLink from '../components/FaqLink';
import { ErrorMessage, Header } from '../components/page-parts';
import PasteInput from '../components/PasteInput';
import Context from '../context';
import { DataUploaderMode } from '../types';
import { getTabName, uploadConfig } from '../utils';
import SampleFile from './sample';

interface UploadProps {
  loading: boolean;
  onCancel: () => void;
  mode: DataUploaderMode;
  setMode: (arg0: DataUploaderMode) => void;
  onUpload: (data: File) => void;
  onPaste: (data: Blob, isSample?: boolean) => void;
}

const Upload = ({ onCancel, onUpload, onPaste, loading, mode, setMode }: UploadProps) => {
  const hasPrivateAnalytics = useHasFF('private_analytics');
  const { error } = useContext(Context);
  const { hasCompletedAnUpload, updateSettings, settings } = useContext(UserContext);

  // TODO: When the capability to paste is added for privates, the following line should be only settings?.user?.[UPLOAD_TYPE_KEY];
  const defaultUploader = mode === DataUploaderMode.Privates ? TABS.UPLOAD : settings?.user?.[UPLOAD_TYPE_KEY];
  const [tab, setTab] = useState<TABS>(defaultUploader || TABS.UPLOAD);

  const [errorMessage, setErrorMessage] = useState<string | React.ReactNode>(error);
  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  const showProceedWithSample = mode === DataUploaderMode.Returns && !hasCompletedAnUpload;
  const onContinueWithSample = () => {
    const data = new Blob([SampleFile], { type: 'text/plain' });
    onPaste(data, true);
  };

  const onSwitchTab = useCallback(
    (selectedTab: TABS) => {
      setTab(selectedTab);
      const updatedUserSettings = {
        [UPLOAD_TYPE_KEY]: selectedTab,
      };
      updateSettings(updatedUserSettings);
      analyticsService.uploadTabChanged({
        TabName: getTabName(selectedTab),
        Nav: mode === DataUploaderMode.NewNavs,
        isPrivatesUploader: mode === DataUploaderMode.Privates,
      });
    },
    [updateSettings, mode],
  );
  const resetToInitial = useCallback(() => setMode(DataUploaderMode.Initial), [setMode]);

  const uploadType = uploadConfig[mode].uploadType;
  const items = compact([
    {
      label: `Upload ${uploadType}`,
      value: TABS.UPLOAD,
    },
    mode !== DataUploaderMode.Privates
      ? {
          label: `Paste ${uploadType}`,
          value: TABS.PASTE,
        }
      : null,
    {
      label: 'Formats and Templates',
      value: TABS.TEMPLATES,
    },
  ]);

  const proceedWithSampleProps = showProceedWithSample
    ? {
        primaryLabel: 'Proceed with sample data',
        primaryClassName: 'qa-upload-sample',
        onPrimaryClick: onContinueWithSample,
      }
    : {};

  const startOverProps = hasPrivateAnalytics
    ? {
        onSecondaryClick: resetToInitial,
        secondaryLabel: 'START OVER',
        secondaryClassName: 'qa-start-over',
        secondaryDisabled: loading, // navigating away while loading the file causes bad state when the promise returns
      }
    : {};
  const modalFooterProps = {
    onCancel,
    cancelClassName: 'qa-upload-cancel',
    ...startOverProps,
    ...proceedWithSampleProps,
  };

  return (
    <>
      <Header>
        <h1>{uploadConfig[mode].uploadTitle}</h1>
        <h2>{uploadConfig[mode].uploadSecondaryTitle}</h2>
        <FlexRow>
          <h3>
            Have more questions? Visit the <FaqLink />.
          </h3>
        </FlexRow>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Header>

      <MainRow>
        <SideBar>
          <SideMenu items={items} onClick={onSwitchTab} selectedItems={tab} withoutContainer allowItemOverflow />
        </SideBar>
        <Content>
          {tab === TABS.UPLOAD && (
            <DataInput onFileChange={onUpload} loading={loading} setError={setErrorMessage} mode={mode} />
          )}
          {tab === TABS.PASTE && <PasteInput onPaste={onPaste} mode={mode} isUploading={loading} />}
          {tab === TABS.TEMPLATES && <DataGallery mode={mode} />}
        </Content>
      </MainRow>
      <StyledModalFooter {...modalFooterProps} />
    </>
  );
};

export default Upload;

const Content = styled.div`
  margin: 0 20px 0 15px;
  width: 100%;
`;

const SideBar = styled.div`
  width: 180px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainRow = styled(FlexRow)`
  margin-top: 10px;
  flex: 1;
`;

const StyledModalFooter = styled(ModalFooter)`
  border-radius: 4px;
`;
