import React, { type ComponentProps, type ReactNode, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { copyPrivatePortfolio, type PrivatePortfolioNode, savePrivatePortfolio } from 'venn-api';
import { type SearchMenuItem } from 'venn-components';
import type ModalFooter from '../../modal/ModalFooter';
import { IconPosition, LoadingSize, selectClasses, Spinner } from 'venn-ui-kit';
import ConditionalOverlay from '../../conditional-overlay';
import { useDebounce } from '../../hooks';
import RequiredLabel from '../../label';
import { CreateFromScratchFooter } from '../../search-menu/components/CreateCompositeFooter';
import SearchMenuBar from '../../search-menu/SearchMenuBar';
import BaseStatefulInput from '../../stateful-input/StatefulInput';
import { useValidatePrivatePortfolioName } from '../../utils/useValidatePrivatePortfolioName';
import Field from '../validation/Field';
import CreatePrivatePortfolioContext, {
  type CreatePrivatePortfolioContext as CreatePrivatePortfolioContextType,
} from './CreatePrivatePortfolioContext';
import classNames from 'classnames';
import { UniversalUploaderFooter } from '../data-uploader/components/page-parts/UniversalUploaderFooter';

const DEBOUNCE_DELAY = 500;

type CreatePrivatePortfolioStepContainerFooterProps = {
  onCancel: () => void;
  onPrivatePortfolioCreated: (portfolio: PrivatePortfolioNode) => void;
  investments?: PrivatePortfolioNode[];
} & Partial<ComponentProps<typeof ModalFooter>>;

const NameInput = ({
  name,
  setName,
}: {
  name: CreatePrivatePortfolioContextType['name'];
  setName: CreatePrivatePortfolioContextType['setName'];
}) => {
  const [debouncedName] = useDebounce(name.value, DEBOUNCE_DELAY);

  useValidatePrivatePortfolioName(
    debouncedName,
    useMemo(
      () => ({
        onEmpty: () => setName((name) => ({ ...name, error: '' })),
        onValid: () => setName((name) => ({ ...name, error: '' })),
        onInvalid: (error: string) => setName((name) => ({ ...name, error })),
        setIsLoading: (isLoading: boolean) => setName((name) => ({ ...name, isLoading })),
      }),
      [setName],
    ),
  );

  return (
    <Field error={name.error}>
      <Label required>Portfolio name:</Label>
      <StatefulInput
        aria-label="Portfolio name input"
        placeholder="Enter portfolio name"
        value={name.value}
        onChange={(value) => setName((name) => ({ ...name, value, isLoading: true }))}
        selectOnFocus
        icon={name.isLoading ? <Spinner size={LoadingSize.micro} /> : null}
        iconPosition={IconPosition.Right}
      />
    </Field>
  );
};

const SourcePortfolioInput = ({
  sourcePortfolio,
  setSourcePortfolio,
}: {
  sourcePortfolio: CreatePrivatePortfolioContextType['sourcePortfolio'];
  setSourcePortfolio: CreatePrivatePortfolioContextType['setSourcePortfolio'];
}) => {
  const setValue = (value: SearchMenuItem | null) =>
    setSourcePortfolio((sourcePortfolio) => ({ ...sourcePortfolio, value }));

  return (
    <Field error={sourcePortfolio.error}>
      <Label>Create new portfolio from:</Label>
      <StyledSearchMenuBar
        smallScreen
        autofocus={false}
        customPlaceholder="Create from scratch..."
        value={sourcePortfolio.value}
        isClearable
        portfoliosOnly
        privateAssetSearchMode="PRIVATES_ONLY"
        includeTags={false}
        includeBenchmarks={false}
        showRecentlyAnalyzed={false}
        onSelected={setValue}
        location="Create private portfolio"
        footer={CreateFromScratchFooter(() => setValue(null))}
      />
    </Field>
  );
};

function isValid(name: CreatePrivatePortfolioContextType['name']) {
  return name.value !== '' && name.error === '' && !name.isLoading;
}

function getTooltip(name: CreatePrivatePortfolioContextType['name']) {
  if (name.value === '') {
    return 'Please enter portfolio name';
  }
  if (name.isLoading) {
    return 'Please wait while we validate portfolio name';
  }
  return '';
}

const useCreatePrivatePortfolioFormState = (): CreatePrivatePortfolioContextType => {
  const [name, setName] = useState<CreatePrivatePortfolioContextType['name']>({
    value: '',
    error: '',
    isLoading: false,
  });

  const [sourcePortfolio, setSourcePortfolio] = useState<CreatePrivatePortfolioContextType['sourcePortfolio']>({
    value: null,
    error: '',
    isLoading: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const createPortfolio = async (investments: PrivatePortfolioNode[]) => {
    if (!isValid(name)) {
      throw new Error('Invalid name');
    }

    const sourcePortfolioId = sourcePortfolio.value?.searchResult?.privatePortfolioId;

    try {
      setIsSubmitting(true);
      let portfolio: PrivatePortfolioNode;
      if (sourcePortfolioId != null) {
        const response = (await copyPrivatePortfolio(sourcePortfolioId, name.value)).content;

        const investmentsWithNewParent: PrivatePortfolioNode[] = investments.map((i) => {
          return { ...i, rootNodeId: response.id, parentNodeId: response.id };
        });
        const modifiedPortfolio = { ...response, children: response.children.concat(investmentsWithNewParent) };
        const newPortfolio = await savePrivatePortfolio(modifiedPortfolio);
        portfolio = newPortfolio.content;
      } else {
        const response = await savePrivatePortfolio({ name: name.value, children: investments });
        portfolio = response.content;
      }
      return portfolio;
    } finally {
      setIsSubmitting(false);
    }
  };

  return { name, setName, sourcePortfolio, setSourcePortfolio, createPortfolio, isSubmitting };
};

export const CreatePrivatePortfolioStepContainer = {
  Root: ({ children }: { children: ReactNode }) => {
    const state = useCreatePrivatePortfolioFormState();
    return <CreatePrivatePortfolioContext.Provider value={state}>{children}</CreatePrivatePortfolioContext.Provider>;
  },
  Overlay: ({ children }: { children: React.ReactNode }) => {
    const { isSubmitting } = useContext(CreatePrivatePortfolioContext);
    return (
      <StyledConditionalOverlay center condition={isSubmitting} contentClassName="conditional-overlay-content">
        {children}
      </StyledConditionalOverlay>
    );
  },
  Body: () => {
    const { name, setName, sourcePortfolio, setSourcePortfolio } = useContext(CreatePrivatePortfolioContext);
    return (
      <>
        <NameInput name={name} setName={setName} />
        <SourcePortfolioInput sourcePortfolio={sourcePortfolio} setSourcePortfolio={setSourcePortfolio} />
      </>
    );
  },
  Footer: ({
    className,
    onCancel,
    onPrivatePortfolioCreated,
    investments = [],
    ...props
  }: CreatePrivatePortfolioStepContainerFooterProps) => {
    const { name, createPortfolio } = useContext(CreatePrivatePortfolioContext);

    const baseProps = {
      className: classNames(className, 'no-margin-footer'),
      onCancel,
      disabled: !isValid(name),
      onContinue: async () => {
        const portfolio = await createPortfolio(investments);
        onCancel();
        onPrivatePortfolioCreated(portfolio);
      },
      primaryTooltip: getTooltip(name),
    };
    return <UniversalUploaderFooter {...baseProps} {...props} />;
  },
};

export default CreatePrivatePortfolioStepContainer;

const Label = styled(RequiredLabel)`
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
`;

const StatefulInput = styled(BaseStatefulInput)`
  display: flex;
`;

const StyledSearchMenuBar = styled(SearchMenuBar)`
  .${selectClasses.SearchIcon} {
    display: none;
  }
`;

const StyledConditionalOverlay = styled(ConditionalOverlay)`
  height: 100%;

  & > .conditional-overlay-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
