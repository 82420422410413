import React from 'react';
import type { PortfolioParseResult } from 'venn-api';
import styled from 'styled-components';
import { ColorUtils, GetColor, Icon } from 'venn-ui-kit';
import { hasUnmatchedFunds } from './helpers';

export type IndexedPortfolioParseResult = PortfolioParseResult & { originalIndex: number };

type ParseResultProps = {
  data: IndexedPortfolioParseResult;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  selected: boolean;
};

export const ParsedPortfolioOption = ({ data, selected, setSelectedIndex }: ParseResultProps) => {
  const hasError = hasUnmatchedFunds(data.parsedPortfolio);
  return (
    <PortfolioNameContainer
      hasError={hasError}
      onClick={() => setSelectedIndex(data.originalIndex)}
      selected={selected}
    >
      {hasError && <ErrorIcon type="triangle-exclamation" prefix="far" />}
      {data.parsedPortfolio.name}
    </PortfolioNameContainer>
  );
};

const ErrorIcon = styled(Icon)`
  color: ${GetColor.Error};
  padding-right: 2px;
`;

const PortfolioNameContainer = styled.button<{ selected: boolean; hasError: boolean }>`
  border-top: 1px solid ${({ selected }) => (selected ? GetColor.Primary.Dark : GetColor.GreyScale.Grey30)};
  border-bottom: 1px solid ${({ selected }) => (selected ? GetColor.Primary.Dark : GetColor.White)};
  background: ${({ selected }) => (selected ? ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.05) : GetColor.White)};
  color: ${({ hasError }) => (hasError ? GetColor.Error : GetColor.Black)};
  display: flex;
  padding: 8px 16px 8px 24px;
  align-items: baseline;
  gap: 4px;
  align-self: stretch;
  width: 100%;
  font-size: 14px;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
`;
