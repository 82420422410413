import React, { useCallback, useContext, useState } from 'react';
import type { MultiPortfolioParseResult } from 'venn-api';
import styled from 'styled-components';
import { isEmpty, isNil, partition } from 'lodash';
import { GetColor } from 'venn-ui-kit';
import { ScrollableSectionWithStickyHeader } from './review/ScrollableSectionWithStickyHeader';
import { RightContainer } from './shared/layout';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import { IUploadPortfolioView } from '../constants';
import { UniversalUploaderFooter } from '../components/page-parts/UniversalUploaderFooter';

type MultiPortfolioReviewStepProps = Readonly<{
  parsedData: MultiPortfolioParseResult;
  setParsedData: React.Dispatch<React.SetStateAction<MultiPortfolioParseResult | undefined>>;
}>;

export const MultiPortfolioReviewStep = ({ parsedData, setParsedData }: MultiPortfolioReviewStepProps) => {
  const { setView } = useContext(UniversalUploaderContext);

  const onGoBack = useCallback(() => {
    setParsedData(undefined);
    setView(IUploadPortfolioView);
  }, [setParsedData, setView]);

  const [newParsedData, existingParsedData] = partition(
    parsedData.portfolioParseResults.map((data, index) => {
      return {
        ...data,
        originalIndex: index,
      };
    }),
    (data) => isNil(data.parsedPortfolio.id),
  );

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  return (
    <MainWrapper>
      <ContentContainer>
        <LeftContainer>
          <ScrollableSectionWithStickyHeader
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            groupIndex={0}
            groupReverseIndex={isEmpty(newParsedData) ? 0 : 1}
            parsedResults={existingParsedData}
            title="EXISTING PORTFOLIOS"
          />
          <ScrollableSectionWithStickyHeader
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            groupReverseIndex={0}
            groupIndex={isEmpty(existingParsedData) ? 0 : 1}
            parsedResults={newParsedData}
            title="NEW PORTFOLIOS"
          />
        </LeftContainer>
        <RightContainer>{JSON.stringify(parsedData.portfolioParseResults[selectedIndex], null, 2)}</RightContainer>
      </ContentContainer>
      <UniversalUploaderFooter noMargin onCancel={onGoBack} />
    </MainWrapper>
  );
};

const HEADER_HEIGHT = 80;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - ${HEADER_HEIGHT}px);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  flex: 1;
  overflow: hidden;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow: auto;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid ${GetColor.GreyScale.Grey30};
`;
